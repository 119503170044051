import { Flex, Link, Spacer, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React from 'react';
// import { JoinForm } from '../components/JoinForm';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { seo } from '../components/Seo';

interface ContactItem {
  role: string;
  email: string;
}
interface Props {
  data: {
    contactJson: {
      emailDomain: string;
      contacts: ContactItem[];
    };
  };
}

export const Head = seo({ title: 'Contact' });

export default function ContactPage({
  data: {
    contactJson: { emailDomain, contacts },
  },
}: Props) {
  const isSmall = useBreakpointValue({ base: false, sm: true });
  return (
    <Page>
      <PageHeader title="Contact & Join" />

      <VStack width="24em">
        {contacts.map((contact: ContactItem) => {
          if (isSmall) {
            return (
              <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                <Text>{contact.role}</Text>
                <Spacer />
                <Link href={`mailto:${contact.email}@${emailDomain}`} isExternal>
                  {`${contact.email}@${emailDomain}`}
                </Link>
              </Flex>
            );
          }

          return (
            <VStack>
              <Text>{contact.role}</Text>
              <Link href={`mailto:${contact.email}@${emailDomain}`} isExternal>
                {`${contact.email}@${emailDomain}`}
              </Link>
            </VStack>
          );
        })}
      </VStack>

      <VStack>
        <Text>
          To join the union, simply{' '}
          <Link href={`mailto:delegate@${emailDomain}?subject=Join inquiry`} isExternal>
            send an email to the delegate
          </Link>{' '}
          directly.
        </Text>
        {/* <JoinForm /> */}
      </VStack>
    </Page>
  );
}

export const pageQuery = graphql`
  query ContactPageTemplate {
    contactJson {
      emailDomain
      contacts {
        role
        email
      }
    }
  }
`;
